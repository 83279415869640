import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { getFirstAndLastName } from "utils/helperFunctions";
import { validateEmail, validateInputNumber, validateMobile } from "utils/validate";
import { apiCallWithoutAuth } from "utils/apiutil";
import { saveLead } from "constants/api";
import { toast } from 'react-toastify';
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg"
import { Names } from "configs/indianNames";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const BuyInterest = tw.p`mt-2 mb-2 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-300`;

const Div = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;
const DecoratorBlob = styled(SvgDotPattern)(props => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
])
const Loader = tw.div`inline-block w-5 h-5 border-4 border-t-primary-500 rounded-full animate-spin`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Your <span tw="text-primary-500">Pickle Journey</span><wbr /> Awaits</>,
  description = "Transforming 50 years of achar heritage into a digital delight. Share your number, and let us take care of the rest.",
  submitButtonText = "Join the Pickle Revolution",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  imageSrc = EmailIllustrationSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  selectedItem,
  setSelectedItem,
  formSubmitted,
  setFormSubmitted,
  representativeName,
  setRepresentativeName
}) => {
  const [name, setName] = useState("")
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);


  const onInput = (field, value) => {
    value = value || "";
    switch (field) {
      case "name":
        setName(value);
        const { firstN, lastN } = getFirstAndLastName(value);
        setFirstName(firstN);
        setlastName(lastN);
        break;
      case "email":
        setEmail(value)
        break;
      case "mobile":
        if (!value) {
          setMobile(value);
        } else if (value?.length <= 10 && /^\d*$/.test(value)) {
          setMobile(value);
        }
        break;
      case "message":
        setMessage(value)
        break;
    }

  }


  const checkSubmit = () => {
    if (loading) {
      return true;
    } else if (!firstName) {
      return true;
      // } else if(!email || !validateEmail(email)) {
      //   return true;
    } else if (!mobile || !validateMobile(mobile)) {
      return true;
    }
    return false;
  }

  const clearForm = () => {
    setEmail("");
    setFirstName("");
    setlastName("");
    setName("");
    setMessage("");
    setMobile("");
  }

  const submitLead = async () => {
    let _representativeName = Names[Math.floor(Math.random() * Names.length)] || "Priya Verma";
    setRepresentativeName(_representativeName)
    setLoading(true)
    let data = {
      firstName,
      lastName,
      email,
      mobile,
      message,
      representativeName: _representativeName,
      item: selectedItem?.title || ""
    }
    const save = await apiCallWithoutAuth(saveLead, "post", data, 'application/json', 1);

    if (save?.meta?.code === 200) {
      clearForm();
      // toast.success('Submitted successfully!');
      setFormSubmitted(true)
    } else {
      toast.error(save?.meta?.message || 'Something went wrong!');
    }

    setLoading(false)


  }
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (

    <Container >
      <TwoColumn>
        <ImageColumn>
          <Image style={{ width: "100%" }} css={imageCss} src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow} imageRounded={imageRounded} />
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft} id="leadForm">
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Div>
              {selectedItem ?
                <div tw="flex-row">
                  <BuyInterest>You've made a great choice </BuyInterest>
                   <Image style={{width:"120px"}} css={tw`items-center justify-center`} src={selectedItem.imageSrc} />
                   
                  <BuyInterest>{selectedItem.title}</BuyInterest>
                </div>
                : ""}
              <Input type="text" value={name} onChange={(e) => onInput("name", e.target.value)} name="name" placeholder="Name" />
              <div style={{ width: "100%", display: "inline-flex" }}>
                <span style={{ fontWeight: 300, fontSize: "14px", color: "#999", position: "relative", top: "36px", height: " 35px", borderBottom: "2px solid #e2e8f0" }}>+91 - &nbsp;</span>
                <Input style={{ width: "90%" }} type="tel" value={mobile} onChange={(e) => onInput("mobile", e.target.value)} name="mobile" placeholder="Mobile" />

              </div>
              {/* <Input type="email" value={email} onChange={(e) => onInput("email", e.target.value)} name="email" placeholder="Your Email Address" /> */}
              {selectedItem ? "" : <Textarea name="message" value={message} onChange={(e) => onInput("message", e.target.value)} placeholder="Any special request(Optional)" />}
              <SubmitButton style={{ opacity: checkSubmit() ? "50%" : "100%" }} type="submit" onClick={submitLead} disabled={checkSubmit()}>
                {submitButtonText} &nbsp; {loading ? <Loader style={{ position: "relative", top: "4px" }} /> : ""}
              </SubmitButton>
            </Div>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
