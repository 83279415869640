import axios from 'axios'
import CONFIG from '../configs/config'
// import { getLocalStoreData } from '../Utils/localstore'
// import { financeSignedUrl } from '../Constants/api'
export const apiCallWithoutAuth = async (
    endpoint,
    method = 'get',
    body = {},
    contentType = 'application/json',
    service = 1,
    cancelToken
) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': contentType
    }
    let url = "";
    switch(service){
        case 1:
            url = CONFIG.localPath;
            break;

    }
    const resp = await axios({
        method,
        baseURL: url,
        url: endpoint,
        data: body,
        params: method.toLowerCase() === 'get' ? body : null,
        paramsSerializer: toQueryString,
        transformRequest: data => {
            if (Object.prototype.toString.call(data) === '[object FormData]') {
                return data
            }
            if (Object.prototype.toString.call(data) === '[object Array]') {
                return JSON.stringify(data)
            }
            const res = {}
            Object.keys(data).forEach(key => {
                res[key] = data[key]
            })
            return JSON.stringify(res)
        },
        headers,
        cancelToken: cancelToken
    })
        .then(checkStatus)
        .then(response => response.data)
        .catch(error => error)
    return resp
}
export const apiCallWithAuth = async (
  endpoint,
  method = "get",
  body = {},
  contentType = "application/json",
  service = 1,
  cancelToken
) => {
  try {
    // const token = getLocalStoreData()["Token"];
    const headers = {
      Accept: "application/json",
      "Content-Type": contentType,
    //   Authorization: token,
    };

    let url = "";
    switch(service){
        case 1:
            url = CONFIG.localPath;
            break;
    }
    const resp = await axios({
      method,
      baseURL: url,
      url: endpoint,
      data: body,
      params: method.toLowerCase() === "get" ? body : null,
      paramsSerializer: toQueryString,
      transformRequest: (data) => {
        if (Object.prototype.toString.call(data) === "[object FormData]") {
          return data;
        }
        if (Object.prototype.toString.call(data) === "[object Array]") {
          return JSON.stringify(data);
        }
        const res = {};
        Object.keys(data).forEach((key) => {
          res[key] = data[key];
        });
        return JSON.stringify(res);
      },
      headers,
      cancelToken: cancelToken,
    })
      .then(checkStatus)
      .then((response) => response.data)
      .catch((error) => error);
    return resp;
  } catch (error) {
    // console.log('api token', localStorage.getItem('ssp-persist'))
    console.error('api call error', error);
    return error?.response?.data || {};
  }
};
export const apiCallWithAuthParamOnly = async (
    endpoint,
    method = 'get',
    body = {},
    contentType = 'application/json',
    service = 1,
    params = {}
) => {
    const token = ""; //getLocalStoreData()['Token']
    const headers = {
        Accept: 'application/json',
        'Content-Type': contentType,
        'Authorization': token
    }
    let url = "";
    switch(service){
        case 1:
            url = CONFIG.localPath;
            break;
    }
    const resp = await axios({
        method,
        baseURL: url,
        url: endpoint+"/"+body,
        data: {},
        params,
        // params: method.toLowerCase() === 'get' ? body : null,
        // paramsSerializer: toQueryString,
        // transformRequest: data => {
        //     if (Object.prototype.toString.call(data) === '[object FormData]') {
        //         return data
        //     }
        //     if (Object.prototype.toString.call(data) === '[object Array]') {
        //         return JSON.stringify(data)
        //     }
        //     const res = {}
        //     Object.keys(data).forEach(key => {
        //         res[key] = data[key]
        //     })
        //     return JSON.stringify(res)
        // },
        headers,
    })
        .then(checkStatus)
        .then(response => response.data)
        .catch(error => error)
    return resp
}
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    } else {
        const error = new Error(response.statusText)
        error.response = response
        throw error
    }
}
function toQueryString(obj) {
    const keys = Object.keys(obj)
    keys.sort()
    const parts = []
    for (const field in keys) {
      const key = keys[field]
      if (obj.hasOwnProperty(key) && !!obj[key] && !!obj[key].toString().length) {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]).replace(/%20/g, '+'))
      }
    }
    return parts.join('&')
  }

// export const getSignedUrl = async (url) => {
//   try {
//     const { Data } = await apiCallWithAuth(financeSignedUrl, "POST", {
//       url,
//     });
//     return Data?.signedUrl;
//   } catch (error) {
//     console.error('Doc fetch error', error);
//     return "";
//   }
// };

export const convertTimeString = (inputTimeString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(inputTimeString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Add leading zero to minutes if necessary
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const outputTimeString = `${day} ${month} ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  return outputTimeString;
};
