import React, { useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import LeadForm from "components/forms/TwoColContactUsWithIllustrationFullForm"
import Footer from "components/footers/FiveColumnWithInputForm.js";

import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";
import heroImage from "images/achaar.jpg";
import descriptionImage from "images/achar_2.jpg";
import LeadFormSrc from "images/leadFormLeft.jpg";
import subheadingImage from "images/achar_3.jpeg";
import ThankYouPage from "ThankYouPage";

export default () => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [representativeName, setRepresentativeName] = useState("");
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Delicious & Affordable <HighlightedText>Pickle Near You.</HighlightedText></>}
        description="Let Achaari Burni add a zing to your meals with pickles made fresh, just for you, and delivered with love."
        imageSrc={heroImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Know More"
        watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        subheading={<Subheading>Established Since 1975</Subheading>}
        heading={
          <>
            We've been serving for
            <wbr /> <HighlightedText>over 50 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            For 50 years, Achaari Burni has served pickles that make meals unforgettable, from aam and neembu to chicken and mutton. Straight from the Himalayas, every jar is packed with authentic flavors and love.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Order Now"
        imageSrc={
          descriptionImage
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Taste Our <HighlightedText>Signature Achaar.</HighlightedText>
          </>
        }
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setFormSubmitted={setFormSubmitted}
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Free Delivery",
            description: "Get your pickles delivered anywhere in India, absolutely free!",
            // url: "https://google.com"
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Master Chefs",
            description: "Our skilled chefs create the perfect pickle every time.",
            // url: "https://timerse.com"
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Bulk Orders",
            description: "We take bulk orders for your family’s pickle cravings!",
            // url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "24000+",
          },
          {
            key: "Customers",
            value: "1400+"
          },
          {
            key: "Chefs",
            value: "10+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="#ordernow"
        imageInsideDiv={false}
        imageSrc={subheadingImage}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <DownloadApp
        text={<>form to collect leads <HighlightedTextInverse>Form to collect leads</HighlightedTextInverse></>}
      /> */}
      {
        formSubmitted ?
          <ThankYouPage representativeName={representativeName} />
          : <LeadForm

            buttonRounded={false}
            textOnLeft={false}
            primaryButtonText="Order Now"
            imageSrc={
              LeadFormSrc
            }
            imageCss={imageCss}
            imageDecoratorBlob={true}
            imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            formSubmitted={formSubmitted}
            setFormSubmitted={setFormSubmitted}
            setRepresentativeName={setRepresentativeName}
          />
      }


      <Footer />
    </AnimationRevealPage>
  );
}
