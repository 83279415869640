export const Names = [
    "Arjun Sharma",
    "Priya Verma",
    "Rahul Gupta",
    "Sneha Joshi",
    "Rajesh Kumar",
    "Pooja Singh",
    "Ankit Patel",
    "Kavita Mehra",
    "Amit Malhotra",
    "Ritu Desai",
    "Sameer Jain",
    "Neha Kapoor",
    "Sandeep Aggarwal",
    "Deepa Iyer",
    "Manish Tiwari",
    "Sunita Yadav",
    "Vikram Choudhary",
    "Anjali Reddy",
    "Karan Nair",
    "Shreya Dixit",
    "Ashok Bansal",
    "Megha Rao",
    "Nitin Bhatt",
    "Pallavi Kulkarni",
    "Varun Shetty",
    "Swati Shah",
    "Ramesh Chauhan",
    "Snehal Patil",
    "Mohit Sinha",
    "Ritika Khurana",
    "Vivek Das",
    "Meena Dutta",
    "Siddharth Sen",
    "Aarti Pandey",
    "Gaurav Tripathi",
    "Anusha Pillai",
    "Rohit Saxena",
    "Priyanka Ghosh",
    "Akshay Menon",
    "Nidhi Jain",
    "Harish Mishra",
    "Seema Vaidya",
    "Tarun Roy",
    "Bhavana Chatterjee",
    "Abhishek Kulkarni",
    "Roshni Sharma",
    "Dinesh Deshmukh",
    "Shruti Kaushik",
    "Sanjay Rangan",
    "Lakshmi Natarajan"
]