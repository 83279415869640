exports.validateName = (name) => {
    return /^([^0-9]*)$/.test(name);
  }
  
  exports.validateEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email?.toLowerCase());
  };
  
  exports.validateMobile = (mobile) => {
    return /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[123456789]\d{9}$/.test(mobile);
  };
  
  exports.validateInputNumber = (input) => {
    return /^"?\d+"?$/.test(input);
  };

  exports.validateUUID = (uuid) => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(uuid);
  }
  
  exports.validatePassport = (passport) => {
    return /^(?!^0+$)[A-Z0-9]{6,9}$/.test(passport);
  };
  
  exports.validateLeverageMail = (email) => {
    const allowedDomains = [
      "leveragemails.com",
       "email.flyfinance.com" 
      ];
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
    if (emailRegex.test(email)) {    
      const domain = email.split("@")[1];
      return allowedDomains.includes(domain);
    }
    return false;
  };
  
  exports.validateApplicationId = (app_id) => {
    return /^(APP-).*/.test(app_id);
  };
  exports.uppercaseWords = (str) => {
    return str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
  };
  exports.handleSpaces = (event) => {
    let e = window.event || event;
  
    let key = e.keyCode;
  
    //space pressed
  
    if (key === 32) {
      if (event.target.value < 1) event.preventDefault();
    }
  };
  
  // } else if (value.match('(0|91)?[7-9][0-9]{9}')) {
  //     mobile = value;
  // } else if (value.toLowerCase().match('^(?!^0+$)[a-zA-Z0-9]{6,9}$')) {  or ^[A-Z][0-9]{8}$
  //     passport = value;
  // }
  exports.formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    if (e.target.value.length === 10 && e.keyCode !== 8) {
      return true && e.preventDefault();
    }
    return checkIfNum && e.preventDefault();
  };
  
  exports.validatePassportV2 = (e, country) => {
    let regex = ''
    if (country == 'India')
         regex = (/^[A-Za-z]{1}[A-Za-z-0-9]{1}[0-9]{6}$/.test(e) && e.length === 8)
    else if(country == 'Bhutan')        
        regex = (/[a-z]/i.test(e[0]) && /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(e) && e.length === 7)
    else if(country == 'US' || country == 'USA' || country == 'United States')        
        regex = (e.length === 9 || /[0-9]{9}/.test(e) )
    else
        regex = (!(e.length < 7 || e.length > 10))
    // if (['Bangladesh', 'Nigeria', 'Nepal', 'Somalia', 'Ethiopia'].includes(payload.country))
    //     regex = (!/[a-z]/i.test(e[0]) || !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(e) || (e.length < 8 || e.length > 10))
    // else
    //     regex = (!/[a-z]/i.test(e[0]) || !/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/.test(e) || e.length !== 8)
  
    if (e.length && regex) {
       return true;
    }
    else {
        return false;
    }
  }
  
  exports.validatePassportARN = (country, passport_arn) => {
    let validated = true
    if (!passport_arn) validated = false;
    else if(country == "India") {
        if(passport_arn.length === 13){
          if(passport_arn[2] === "-"){
            let temp = passport_arn.split("-")
            if(temp[0].length === 2 && temp[1].length === 10){
              temp = temp.join("")
              if(isNaN(temp)){
                validated = false
              }
            }
            else {
              validated = false
            }
          }
          else {
            validated = false
          }
        }
        else {
          validated =  false
        }
    }
    return validated
  }
  
  exports.validatePercentageValue = (percent) => {
    const _percent = Number(percent);
    return isNaN(_percent) ? false : _percent >=0 && _percent <= 100;
  }
  exports.formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    if (e.target.value.length === 10 && e.keyCode !== 8) {
      return true && e.preventDefault();
    }
    return checkIfNum && e.preventDefault();
  };
  
  exports.formatInputWithDecimal = (e) => {
    // Prevent characters that are not numbers ("e", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        // e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    if (e.target.value.length === 10 && e.keyCode !== 8) {
      return true && e.preventDefault();
    }
    return checkIfNum && e.preventDefault();
  };
  